.section-heading {
    font-weight: bold;
    font-size: 4.6rem;
    line-height: 5.4rem;
    margin-bottom: 3.8rem;

    @include breakpoint(tablet) {
        font-size: 4rem;
        line-height: 1.2;
        margin-bottom: 3rem;
    }

    @include breakpoint(mobile) {
        font-size: 2.6rem;
        line-height: 125%;
        margin-bottom: 2rem;
    }
}
