.glass {
    padding: 10rem 0;
    background: #f8f8f8;

    @include breakpoint(mobile) {
        padding: 3.2rem 0;
    }
}

.glass__card {
    background: #ffffff;
    border-radius: 3rem;
}

.glass__card-top-row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        padding: 3.2rem 1.6rem;
    }
}

.glass__card-col {
    padding: 7.2rem;

    @include breakpoint(mobile) {
        padding: 3.2rem 1.6rem;
        display: contents;
    }
}

.glass__card-heading {
    margin-bottom: 0;
    @include breakpoint(mobile) {
        margin-bottom: 2rem;
    }
}

.glass__card-text {
    font-size: 2.2rem;
    line-height: 3.4rem;

    @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 2.2rem;
    }
}

.glass__card-bottom-row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include breakpoint(mobile) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.glass__card-inner {
    position: relative;
    border-radius: 3rem;
    z-index: 1;
    background-color: var(--primary-color);
    color: white;
    overflow: hidden;
    min-height: 85rem;
    display: flex;
    flex-direction: column;
    padding: 7.2rem;




  

    @include breakpointMF(small-tablet) {
        &:nth-child(odd) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
           
        }
        &:nth-child(even) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
           
        }



    }

    @include breakpoint(mobile) {
        min-height: 40rem;
        padding: 3.2rem 1.6rem;

        &:nth-child(odd) {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:nth-child(even) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

   

    .no-touch &:hover {
        .glass__card-link {
            opacity: 1;
            visibility: visible;
            transition: all 0.2s;
        }
    }
}

.glass__card-inner-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(29.12deg, rgba(0, 0, 0, 0.4) -6.79%, rgba(0, 0, 0, 0) 157.62%);
    }
}

.glass__card-inner-bg-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.glass__card-heading-block {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.glass__card-heading-block-content {
    width: 50%;
    flex-grow: 1;
}

.glass__card-heading-block-icon {
    width: 12rem;
    height: 12rem;
    flex-shrink: 0;
    margin-left: 5rem;
    object-fit: contain;
    object-position: center;

    @include breakpoint(mobile) {
        width: 6rem;
        height: 6rem;
    }
}

.glass__card-heading-block-type {
    font-size: 2.2rem;
    line-height: 125%;
    color: rgba(white, 0.6);
    margin-bottom: 1.6rem;
    max-width: 40rem;

    @include breakpoint(mobile) {
        font-size: 1.2rem;
        line-height: 125%;
    }
}

.glass__card-heading-block-name {
    font-weight: bold;
    font-size: 4.6rem;
    line-height: 125%;
    font-family: var(--nunito);

    @include breakpoint(mobile) {
        font-size: 2.6rem;
        line-height: 125%;
    }
}

.glass__card-description {
    font-size: 2.2rem;
    line-height: 3.4rem;
    margin-top: auto;

    @include breakpoint(mobile) {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
}

.glass__card-link {
    // opacity: 0;
    // visibility: hidden;
    transition: opacity 0.2s, visibility 0s linear 0.2s, background-color 0.2s, color 0.2s;
    align-self: flex-start;
}
