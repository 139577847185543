.special {
    position: relative;
    z-index: 1;
    padding: 8.5rem 0;

    @include breakpoint(mobile) {
        padding-top: 3.2rem;
        padding-bottom: 5.7rem;
    }
}

.special__promo-slider {
    @include breakpointMF(small-tablet) {
        .swiper-container {
            margin: 0;
            overflow: visible;
        }
    }


    @include breakpoint(mobile) {
        .swiper-slide {
            display: flex;
            flex-direction: column;
            width: 25rem;
           
        }

        .swiper-container {
            overflow: visible;
        }
    }
}

.special__bg-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    pointer-events: none;
}

.special__heading {
    margin-bottom: 5rem;
    @include breakpoint(mobile) {
        margin-bottom: 1.7rem;
    }
}

.special__promo-list {
    @include breakpointMF(small-tablet) {
        width: 100%;
        max-width: 100rem;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 2.5rem;
        grid-auto-rows: minmax(auto, 24rem);
    }
}

.special__promo-list-item {
    display: flex;
    flex-direction: column;
}

.special__promo-card,
.special__get-discount {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 3rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 3rem;
    background-color: #fff;
    color: var(--text-color);
    transition: background-color 0.2s, color 0.2s;

    @include breakpoint(mobile) {
        padding: 2.4rem;
        min-height: 16.5rem;
    }
    &:link,
    &:visited {
        color: var(--text-color);
    }
}

.special__promo-card {
    .no-touch &:hover,
    .touch &:active {
        color: var(--primary-color);
    }
}

.special__get-discount {
    justify-content: center;
    background-color: var(--primary-color);
    color: white;
    background-image: url('../img/get-discount-bg.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 120%;
    font-family: var(--nunito);

    @include breakpoint(mobile) {
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 120%;
    }

    &:link,
    &:visited {
        color: white;
    }

    .no-touch &:hover {
        background-color: var(--primary-color-hover);
    }
}

.special__promo-card-amount {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.5rem;
    font-family: var(--nunito);
    padding: 0.6rem 1.4rem;
    border-radius: 1rem;
    flex-shrink: 0;
    margin-bottom: 1rem;
    background-color: var(--primary-color);
    color: white;
    align-self: flex-start;

    @include breakpoint(mobile) {
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 2.5rem;
        border-radius: 0.6rem;
        margin-bottom: 1.5rem;
        padding: 0.5rem 1rem;
        padding-bottom: 0.4rem;
    }
}

.special__promo-card-title {
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 120%;
    margin-top: auto;

    @include breakpoint(mobile) {
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 120%;
    }
}

.special__promo-card-icon {
    height: 20rem;
    width: auto;
    max-width: none;
    position: absolute;
    right: 0;
    bottom: 0;
    object-fit: contain;
    z-index: -1;
    margin-right: 1rem;
    margin-bottom: -1rem;

    &--slingshot {
        margin-right: -2rem;
    }

    @include breakpoint(mobile) {
        height: 9.2rem;
        margin-right: -2rem;
        margin-bottom: 2rem;
    }
}
