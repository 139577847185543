.why-us {
    padding: 10rem 0;
    background: #f8f8f8;
    background-image: url('../img/why-us-bg.jpg');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: auto 94rem;

    @include breakpoint(mobile) {
        background-image: none;
        padding: 4.8rem 0;
    }
}

.why-us__top-row {
    display: flex;
    align-items: flex-start;

    @include breakpoint(mobile) {
        flex-direction: column;
        align-items: initial;
    }
}

.why-us__content {
    width: 30%;
    flex-grow: 1;

    @include breakpoint(mobile) {
        width: 100%;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        display: contents;
    }
}

.why-us__certificate {
    width: 37.8rem;
    height: auto;
    flex-shrink: 0;
    margin-left: 14rem;
    border-radius: 2rem;

    @include breakpoint(mobile) {
        width: 100%;
        margin: 0;
        margin-bottom: 3rem;
    }
}

.why-us__text {
    font-size: 2.8rem;
    line-height: 150%;
    margin-bottom: 5.5rem;

    @include breakpoint(tablet) {
        font-size: 2.4rem;
        line-height: 3.6rem;
    }

    @include breakpoint(mobile) {
        margin-bottom: 2.2rem;
        font-size: 1.6rem;
        line-height: 150%;
    }
}

.why-us__warning {
    padding: 4rem 4.5rem;
    background: #ffffff;
    border-radius: 3rem;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 150%;
    display: flex;
    align-items: flex-start;

    @include breakpoint(mobile) {
        flex-direction: column;
        align-items: initial;
        font-size: 1.8rem;
        line-height: 140%;
        padding: 2.4rem 2rem;
        order: 10;
    }
    svg {
        width: 1.5rem;
        height: 3.6rem;
        fill: var(--primary-color);
        flex-shrink: 0;
        margin-right: 3.7rem;
        margin-top: 0.7rem;

        @include breakpoint(mobile) {
            width: 1.3rem;
            height: 3.2rem;
            margin: 0;
            margin-bottom: 2rem;
        }
    }
}

.why-us__benefits-list {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 2rem;
    grid-column-gap: 7rem;
    grid-row-gap: 6rem;
    margin-top: 6rem;

    @include breakpoint(small-tablet) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include breakpoint(mobile) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 3rem;
        margin-top: 5rem;
    }
}

.why-us__benefits-icon {
    height: 6rem;
    width: auto;
    object-fit: contain;
    object-position: left center;
    margin-bottom: 3rem;

    @include breakpoint(mobile) {
        height: 4.5rem;
        margin-bottom: 2.2rem;
    }
}

.why-us__benefits-title {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 120%;
    font-family: var(--nunito);
    margin-bottom: 1.5rem;

    @include breakpoint(mobile) {
        font-size: 1.8rem;
        line-height: 120%;
        margin-bottom: 0.5rem;
    }
}

.why-us__benefits-text {
    font-size: 2rem;
    line-height: 2.8rem;
    color: #7a7a7a;

    @include breakpoint(mobile) {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
}

.why-us__difference {
    padding-top: 7rem;
    border-top: 1px solid #e7e7e7;
    margin-top: 7rem;

    @include breakpoint(mobile) {
        padding: 0;
        border: none;
        margin-top: 6.5rem;
    }
}

.why-us__difference-heading {
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 150%;
    font-family: var(--nunito);
    margin-bottom: 4.5rem;
    max-width: 120rem;

    @include breakpoint(mobile) {
        max-width: none;
        margin-bottom: 2.4rem;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 140%;
    }
}

.why-us__difference-list {
    @include breakpointMF(laptop) {
        column-count: 2;
        column-gap: 8rem;
    }
}

.why-us__difference-list-item {
    font-size: 2.2rem;
    line-height: 3.6rem;
    display: flex;
    align-items: flex-start;
    color: rgba(#231f20, 0.7);
    break-inside: avoid;
    margin-bottom: 3rem;

    @include breakpoint(mobile) {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 2rem;

       


        .why-us__difference:not(.show-all) & {
            &:nth-child(n+6) {
                display: none;
            }
        }

       
    }
    &::before {
        content: '';
        flex-shrink: 0;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 1.4rem;
        background-color: var(--primary-color);
        margin-top: 1.3rem;

        @include breakpoint(mobile) {
            width: 0.6rem;
            height: 0.6rem;
            margin-right: 0.8rem;
            margin-top: 0.9rem;
        }
    }
}

.why-us__show-more-btn {
    font-size: 1.4rem;
    line-height: 2.3rem;
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    display: inline-flex;
    align-self: flex-start;

    display: none;

    @include breakpoint(mobile) {
        display: inline-flex;
    }
   
    &:link,
    &:visited {
        color: var(--primary-color);
    }
}
