.photo-comparison {
    padding: 10rem 0;
    position: relative;
    z-index: 100;
    background-color: #f6f6f7;

    @include breakpoint(mobile) {
        padding: 5rem 0;
    }
    .intro-animation & {
        background: transparent;
    }
}

.photo-comparison__card {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    background-color: #fff;
    border-radius: 3rem;
    overflow: hidden;
    transform: translateZ(0);
    backface-visibility: hidden;

    @include breakpoint(mobile) {
        border-radius: 2rem;
        display: flex;
        flex-direction: column-reverse;
    }
}

.photo-comparison__card-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}

.photo-comparison__card-content {
    padding: 6rem;

    @include breakpoint(tablet) {
        padding: 4rem;
    }

    @include breakpoint(mobile) {
        padding: 2rem;
    }
}

.photo-comparison__heading {
    margin-bottom: 3.5rem;
    @include breakpoint(mobile) {
        margin-bottom: 1.2rem;
    }
}

.photo-comparison__text {
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-bottom: 6rem;

    @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 2rem;
    }
}

.photo-comparison__link {
    align-self: flex-start;
}

.photo-comparison__slider {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    user-select: none;
    touch-action: pan-x;

    @include breakpoint(mobile) {
        position: relative;
        height: auto;
        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%;
        }
    }
    .cd-image-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .cd-image-original,
    .cd-image-modified {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .cd-resize-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        transform: translateZ(0);
        backface-visibility: hidden;
        clip-path: polygon(0 0, calc(var(--percentage-shown) * 100%) 0, calc(var(--percentage-shown) * 100%) 100%, 0 100%);
    }

    .cd-handle-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        // background-color: red;
        // opacity: 0.6;
        z-index: 10;
        user-select: none;
        width: calc(var(--percentage-shown) * 100%);
    }

    .cd-handle {
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        align-items: center;
        z-index: 50;
        transform: translateX(50%);
        cursor: pointer;
        svg {
            width: 1.2rem;
            height: 2rem;
            fill: white;
            margin-right: 5rem;

            @include breakpoint(mobile) {
                margin-right: 3rem;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}


.photo-comparison__btns {
    display: flex;
    align-items: center;
}

.photo-comparison__consultation-link {
    display: inline-flex;
    align-items: center;
    justify-self: center;
    border-radius: 2rem;
    background-color: #fff;
    border: 1px solid var(--primary-color);
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    font-family: var(--nunito);
    padding: 2rem 2.2rem;
    color: var(--text-color);
    transition: color 0.2s, background-color 0.2s;
    margin-left: 2.4rem;
    white-space: nowrap;
    @include breakpoint(mobile) {
        width: 4.8rem;
        height: 4.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        line-height: 1.2;
        padding: 0;
        border-radius: 1.5rem;
        margin-left: 1.6rem;
    }
    &:link,
    &:visited {
        color: var(--text-color);
    }

    svg {
        width: 3.2rem;
        height: 3.2rem;
        flex-shrink: 0;
        fill: var(--primary-color);
        margin-right: 1.3rem;
        transition: fill 0.2s;

        @include breakpoint(mobile) {
            width: 2rem;
            height: 2rem;
            margin: 0;
        }
    }

    .no-touch &:hover,
    .touch &:active {
        background-color: var(--primary-color);
        color: white;

        svg {
            fill: white;
        }
    }
}

.photo-comparison__consultation-link-text {
    @include breakpoint(mobile) {
        display: none;
    }
}

