.thickness {
    padding: 10rem 0;
   

    

    @include breakpoint(mobile) {
        padding: 4.8rem 0;
    }
}

.thickness__content {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        right: 0;
        top: 0;
        position: absolute;
        z-index: -1;
        pointer-events: none;
        width: 46rem;
        height: 53rem;
        background-image: url('../img/thickness-illustration.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include breakpoint(mobile) {
            display: none;
        }
    }
}

.thickness__heading {
    max-width: 73rem;
    margin-bottom: 6rem;

    @include breakpoint(mobile) {
        max-width: none;
        margin-bottom: 3rem;
    }
}

.thickness__list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 8rem;
    grid-row-gap: 4rem;

    @include breakpoint(small-tablet) {
        grid-column-gap: 6rem;
    }

    @include breakpoint(mobile) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 4.5rem;
    }
}

.thickness__list-item {
    display: flex;
    flex-direction: column;
}

.thickness__card {
    display: flex;
    flex-direction: column;
}

.thickness__card-icon {
    height: 11rem;
    width: auto;
    flex-shrink: 0;
    max-width: 100%;
    object-fit: contain;
    object-position: left center;
    margin-bottom: 3.5rem;

    @include breakpoint(mobile) {
        height: 10rem;
        margin-bottom: 1.8rem;
    }
}

.thickness__card-title {
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 120%;
    font-family: var(--nunito);
    margin-bottom: 3rem;

    @include breakpoint(mobile) {
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 120%;
        margin-bottom: 1.6rem;
    }
}

.thickness__card-text {
    font-size: 2.2rem;
    line-height: 3.6rem;
    margin-bottom: 6rem;
    color: #7a7a7a;

    @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 2.4rem;
        margin-bottom: 1.6rem;
    }
}

.thickness__card-price {
    margin-top: auto;
    @include breakpoint(mobile) {
        margin: 0;
    }
}

.thickness__card-price-title {
    font-weight: bold;
    font-size: 2.8rem;
    font-family: var(--nunito);
    line-height: 130%;

    @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 130%;
        margin-bottom: 0.3rem;
    }
}

.thickness__card-price-amount {
    font-weight: bold;
    font-size: 4rem;
    line-height: 130%;
    color: var(--primary-color);
    font-family: var(--nunito);

    @include breakpoint(mobile) {
        font-size: 2.4rem;
        line-height: 130%;
    }
}
