.fullscreen-gallery {
    color: white;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.fullscreen-gallery__content {
    .intro-animation & {
        opacity: 0;
        visibility: hidden;
        transform: scale(0.9);

        @media (min-width: 768px) and (max-width: 1024px) {
            opacity: 1;
            visibility: inherit;
            transform: none;
        }
    }
}

.fullscreen-gallery__bg-slider {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    mask-image: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, 1) 5rem);

    @media (min-width: 768px) and (max-width: 1024px) {
        mask-image: none;
    }

    @include breakpoint(mobile) {
        mask-image: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, 1) 7rem);
        backface-visibility: hidden;
        transform: translateZ(0);
    }

    .intro-animation & {
        opacity: 0;
        visibility: hidden;
        @media (min-width: 768px) and (max-width: 1024px) {
            opacity: 1;
            visibility: inherit;
        }

        @include breakpoint(mobile) {
            opacity: 1;
            visibility: inherit;
        }
    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-wrapper {
        height: 100%;
    }

    .swiper-slide {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.fullscreen-gallery__bg-slider-card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.2);
    }
}

.fullscreen-gallery__bg-slider-card-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.fullscreen-gallery__content {
    min-height: 100vh;
    padding: 10rem 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) and (max-width: 1024px) {
        min-height: 100rem;
    }

    @include breakpoint(small-tablet) {
        padding: 10rem 0;
    }

    @include breakpoint(mobile) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
}

.fullscreen-gallery__heading {
    margin-bottom: 5rem;

    @include breakpoint(mobile) {
        margin-bottom: 2.5rem;
    }
}

.fullscreen-gallery__categories {
    display: flex;
    align-items: center;
    margin-bottom: 6rem;

    @include breakpoint(mobile) {
        margin-bottom: 3rem;
        display: grid;
        align-items: initial;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 0.5rem;
        grid-row-gap: 2rem;
    }
}

.fullscreen-gallery__categories-link {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 1.9rem 1.8rem;
    transition: background-color 0.2s, color 0.2s;
    color: white;
    width: 13.6rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;

    margin-right: 1rem;
    position: relative;

    @include breakpoint(mobile) {
        width: 100%;
        padding: 1.7rem 1.2rem;
        margin: 0;
        flex-grow: 1;

        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.4rem;
    }
    &:last-child {
        margin-right: 0;
    }

    &:link,
    &:visited {
        color: white;
    }

    &.active {
        // background-color: white;
        color: var(--text-color);

        .fullscreen-gallery__categories-link-icon svg {
            fill: var(--primary-color);
        }

        .fullscreen-gallery__categories-link-bg {
            opacity: 1;
        }

        &:link,
        &:visited {
            color: var(--text-color);
        }
    }
}

.fullscreen-gallery__categories-link-title {
    font-weight: 500;
}

.fullscreen-gallery__categories-link-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: -1;

    rect {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
    }
}

.fullscreen-gallery__categories-link-icon {
    width: 100%;
    margin-bottom: 1.5rem;
    svg {
        height: 6rem;
        width: auto;
        flex-shrink: 1;
        fill: white;
        transition: fill 0.2s;
        display: block;
        width: 100%;

        @include breakpoint(mobile) {
            height: 4rem;
        }
    }
}

.fullscreen-gallery__inner-slider {
    width: 100%;
    max-width: 84rem;
}

.fullscreen-gallery__bottom-row {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

.fullscreen-gallery__inner-slider-card {
    display: flex;
    flex-direction: column;
}

.fullscreen-gallery__inner-slider-card-category {
    font-size: 2.4rem;
    line-height: 3.6rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 1.2rem;

    @include breakpoint(mobile) {
        font-size: 1.6rem;
        line-height: 3.6rem;
        margin-bottom: 0;
    }
}

.fullscreen-gallery__inner-slider-card-text {
    font-size: 2.4rem;
    line-height: 4.3rem;
    margin-bottom: 3rem;

    @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 2.4rem;
        margin-bottom: 1.6rem;
    }
}

.fullscreen-gallery__inner-slider-card-link {
    align-self: flex-start;
}

.fullscreen-gallery__arrows {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 5rem;
    align-self: flex-end;
}

.fullscreen-gallery__arrow {
    flex-shrink: 0;
    width: 7.4rem;
    height: 7.4rem;
    position: relative;

    transition: border-color 0.2s, background-color 0.2s, color 0.2s, opacity 0.2s;

    margin-right: 2rem;
    &:last-child {
        margin-right: 0;
    }
    .icon-arrow-thin-left,
    .icon-arrow-thin-right {
        fill: white;
        width: 1.5rem;
        height: 2.5rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.swiper-button-disabled {
        pointer-events: none;
        opacity: 0;
    }
}

.fullscreen-gallery__arrow-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s;

    .fullscreen-gallery__arrow.autoplaying & {
        opacity: 1;
    }

    rect {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
    }
}
