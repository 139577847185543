.page-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 400;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    color: white;

    &.fixed {
        background-color: white;
        color: var(--text-color);
        box-shadow: 0 0 1rem rgba(black, 0.1);
    }
}

.page-header__row {
    display: flex;

    padding: 4rem 0;
    transition: padding 0.2s;

    @include breakpoint(tablet) {
        padding: 2.5rem 0;
        align-items: center;
    }

    @include breakpoint(mobile) {
        padding: 1.7rem 0;
    }
    .page-header.fixed & {
        padding: 1.7rem 0;
    }
}

.page-header__logo {
    flex-shrink: 0;
    position: relative;
    margin-right: 3rem;
    flex-shrink: 0;
    margin-top: 0.3rem;

    @include breakpoint(tablet) {
        display: none;
    }
}

.page-header__logo-image {
    width: 23rem;
    display: block;
    height: auto;

    &:last-child {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s, visibility 0s linear 0.2s;

        .page-header.fixed & {
            opacity: 1;
            visibility: inherit;
            transition: opacity 0.2s, visibility 0.2s;
        }
    }
}

.page-header__menu {
    @include breakpointMF(laptop) {
        width: 50%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        &::before,
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: transparent;
            transition: flex-grow 0.2s;
        }

        .page-header.fixed & {
            &::before,
            &::after {
                flex-grow: 1;
            }
        }
    }

    @include breakpoint(tablet) {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1200;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        @include noScrollbar();
        background-color: rgba(35, 31, 32, 0.4);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0s linear 0.3s;

        .mobile-menu-open & {
            opacity: 1;
            visibility: inherit;
            transition: opacity 0.3s, visibility 0.3s;
        }
    }
}

.page-header__menu-inner {
    @include breakpoint(tablet) {
        min-height: 100%;
        background-color: #fff;
        width: 45rem;
        padding: 4rem 3rem;
        color: var(--text-color);
        transform: translateX(-100%);
        transition: transform 0.4s;

        .mobile-menu-open & {
            transform: translateX(0%);
            transition: transform 0.4s ease 0.2s;
        }
    }

    @include breakpoint(mobile) {
        width: 30rem;
        padding: 3.2rem 2rem;
    }
}

.page-header__menu-top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10rem;
    @include breakpointMF(laptop) {
        display: none;
    }

    @include breakpoint(mobile) {
        margin-bottom: 7rem;
    }
}

.page-header__menu-logo {
    flex-shrink: 0;
}

.page-header__menu-logo-image {
    width: 24rem;
    height: auto;

    @include breakpoint(mobile) {
        width: 15.6rem;
    }
}

.page-header__menu-close {
    flex-shrink: 0;
    margin-left: 3rem;
    width: 3.2rem;
    height: 3.2rem;

    svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: var(--text-color);
    }
    @include breakpoint(mobile) {
        width: 2rem;
        height: 2rem;
    }
}

.page-header__menu-contacts {
    display: flex;
    align-items: center;
    font-family: var(--nunito);
    @include breakpointMF(laptop) {
        display: none;
    }
}

.page-header__menu-whats-app {
    width: 5rem;
    height: 5rem;
    flex-shrink: 0;
    margin-right: 1.8rem;

    @include breakpoint(mobile) {
        width: 4.5rem;
        height: 4.5rem;
        margin-right: 1.8rem;
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.page-header__menu-phone-link {
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    display: block;

    @include breakpoint(mobile) {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 0.2rem;
    }
}

.page-header__menu-free-call {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.2;
    display: block;

    @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 1.9rem;
    }
}

.page-header__nav {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(tablet) {
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: 7rem;
    }

    @include breakpoint(mobile) {
        margin-bottom: 7rem;
    }
}

.page-header__menu-open-btn {
    flex-shrink: 0;
    margin-right: 3rem;
    display: inline-flex;
    flex-direction: column;

    &::before,
    &::after {
        content: '';
        width: 32px;
        height: 2px;
        margin-bottom: 6px;
        background-color: #fff;
        transition: background-color 0.3s;

        .page-header.fixed & {
            background-color: var(--text-color);
        }
    }

    &::after {
        margin-bottom: 0;
    }

    @include breakpointMF(laptop) {
        display: none;
    }
}

.page-header__nav-link {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;

    flex-shrink: 0;
    margin: 0.5rem 1.5rem 0.5rem 0.5rem;

    margin-left: 0;
    color: white;
    overflow: hidden;
    display: block;
    position: relative;

    @include breakpointMF(laptop) {
        &:link,
        &:visited {
            color: white;
        }

        .page-header.fixed & {
            color: var(--text-color);
            &:link,
            &:visited {
                color: var(--text-color);
            }
        }
    }

    @include breakpoint(tablet) {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.2;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 3.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    @include breakpoint(mobile) {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.2rem;
        margin-bottom: 2.8rem;
    }

    &:last-child {
        margin-right: 0;
    }

    &::before {
        content: attr(data-text);
        position: absolute;
        left: 0;
        width: 100%;
        top: 100%;
        transition: transform 0.25s;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .no-touch &:hover {
        .page-header__nav-link-text {
            transform: translateY(-100%);
        }

        &::before {
            transform: translateY(-100%);
        }
    }
}

.page-header__nav-link-text {
    transition: transform 0.25s;
}

.page-header__contacts-block {
    flex-shrink: 0;
    margin-left: 4rem;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas: 'phone-link whatsapp-link' 'phone-link-text whatsapp-link';
    text-align: right;
    grid-column-gap: 2.2rem;
    grid-row-gap: 0.5rem;
    margin-left: auto;
    align-self: center;
    @include breakpoint(mobile) {
        
        grid-template-areas: 'phone-link whatsapp-link';
        column-gap: .8rem;
        align-items: center;
        grid-template-rows: auto;
    }
}

.page-header__phone-link {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    font-family: var(--nunito);
    grid-area: phone-link;
    justify-self: flex-end;
    color: white;

    @include breakpoint(mobile) {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-top: 0.2rem;
    }
    &:link,
    &:visited {
        color: white;
    }

    .page-header.fixed & {
        color: var(--text-color);
        &:link,
        &:visited {
            color: var(--text-color);
        }
    }
}

.page-header__phone-link-text {
    justify-self: flex-end;
    grid-area: phone-link-text;

    @include breakpoint(mobile) {
        display: none;
    }
}

.page-header__whats-app-link {
    width: 4.7rem;
    height: 4.7rem;
    flex-shrink: 0;
    grid-area: whatsapp-link;

    @include breakpoint(mobile) {
        width: 2.4rem;
        height: 2.4rem;
    }
    svg {
        width: 100%;
        height: 100%;
        display: block;
    }
}
