.advantage {
    background-color: #f6f6f7;
    position: relative;
    z-index: 1;
    padding: 6.5rem 0;

    @include breakpoint(mobile) {
        padding: 3.2rem 0;
    }
}

.advantage__bg-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: center;
}

.advantage__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 8rem;

    @include breakpoint(mobile) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 2.5rem;
    }
}

.advantage__heading {
    font-family: var(--nunito);
    font-weight: bold;
    font-size: 3.5rem;
    line-height: 4.8rem;

    @include breakpoint(mobile) {
        font-weight: bold;
        font-size: 2rem;
        line-height: 110%;
    }
}

.advantage__text {
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-bottom: 3rem;

    @include breakpoint(mobile) {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
