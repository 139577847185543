.how-to-choose {
   
    background-color: var(--primary-color);
    color: white;
    position: relative;
    z-index: 1;

    @include breakpoint(mobile) {
       
    }
}

.how-to-choose__card-table {
    position: absolute;
    right: -10rem;
    bottom: 0;
    height: 45rem;
    width: auto;
    object-fit: contain;
    object-position: right bottom;
    margin-right: -1rem;
    z-index: -1;

    @include breakpoint(mobile) {
        height: auto;
        object-position: center bottom;
        margin: 0;
        width: 100%;
        z-index: 1;
        position: static;
        right: auto;
        bottom: auto;
       
    }
}

.how-to-choose__row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 8rem;
    display: grid;
    position: relative;
    padding: 8rem 0;
    @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        grid-gap: initial;
        padding: 3.2rem 0;
    }
}

.how-to-choose__card {
    background: #ffffff;
    box-shadow: 0px 0.4rem 4rem rgba(0, 0, 0, 0.1);
    border-radius: 3rem;
    padding: 5rem 7rem;
    color: var(--text-color);

    @include breakpoint(small-tablet) {
        padding: 4rem;
    }

    @include breakpoint(mobile) {
        border-radius: 2rem;
        padding: 3.2rem 1.6rem;
    }
}

.how-to-choose__card-heading {
    margin-bottom: 3rem;

    @include breakpoint(mobile) {
        margin-bottom: 1.8rem;
        font-size: 2.4rem;
        line-height: 3.2rem;
    }
}

.how-to-choose__card-text {
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-bottom: 2rem;

    @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 1.9rem;
    }
}

.how-to-choose__card-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2.4rem;

    @include breakpoint(mobile) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 1.6rem;
    }
}

.how-to-choose__card-form-field {
}

.how-to-choose__card-form-field--fullwidth-on-mobile {
    @include breakpoint(mobile) {
        grid-column: 1 / -1;
    }
}

.how-to-choose__card-form-submit {
    width: 100%;
}

.how-to-choose__card-form-input {
    height: 7.2rem;
    background: #f7f7f7;

    border-radius: 2rem;
    padding: 0 2.4rem;
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    font-family: var(--nunito);
    color: var(--text-color);
    border: 1px solid #f7f7f7;

    &.parsley-error {
        border-color: var(--primary-color);
    }
    @include placeholder {
        color: rgba(#231f20, 0.5);
    }

    @include breakpoint(mobile) {
        height: 5.6rem;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.5rem;
        padding: 0 1.6rem;
        border-radius: 1rem;
    }
}

.how-to-choose__card-form-policy {
    font-size: 1.4rem;
    line-height: 2rem;
    color: rgba(0, 0, 0, 0.5);
    align-self: center;

    @include breakpoint(mobile) {
        font-size: 0.9rem;
        line-height: 1.2;
    }

    a {
        color: var(--primary-color);
        border-bottom: 1px solid var(--primary-color);
        transition: color 0.2s, border-color 0.2s;
        &:link,
        &:visited {
            color: var(--primary-color);
        }

        .no-touch &:hover {
            border-color: transparent;
        }
    }
}

.how-to-choose__sale {
    display: flex;
    align-items: center;
    font-family: var(--nunito);
    align-self: flex-start;

    @include breakpoint(mobile) {
        order: -1;
        flex-direction: column;
        align-self: initial;
        align-items: initial;
        margin-bottom: 2.4rem;
    }
}

.how-to-choose__sale-text {
    width: 30%;
    flex-grow: 1;
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 120%;

    @include breakpoint(mobile) {
        font-weight: bold;
        font-size: 2.4rem;
        line-height: 120%;
        width: 100%;
        flex-grow: 0;
    }
}

.how-to-choose__sale-amount {
    width: 20rem;
    height: 14.7rem;
    flex-shrink: 0;
    margin-left: 2rem;
    background-image: url('../img/sale-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(mobile) {
        align-self: center;
        width: 13.4rem;
        height: 10rem;
        margin: 0;
        margin-bottom: 2.4rem;
        order: -1;
    }
}

.how-to-choose__sale-amount-number {
    font-weight: bold;
    font-size: 4.8rem;
    line-height: 120%;

    @include breakpoint(mobile) {
        font-weight: bold;
        font-size: 3.2rem;
        line-height: 120%;
    }
}

.how-to-choose__card-btns {
    display: flex;
    align-items: center;
    margin-top: 5rem;
    flex-wrap: wrap;

    @include breakpoint(tablet) {
        flex-direction: column;
        align-items: initial;
        flex-wrap: nowrap;
    }

    @include breakpoint(mobile) {
        flex-wrap: wrap;
        margin: 0;
        flex-direction: row;
    }
}

.how-to-choose__card-btn {
    margin-right: 2.4rem;
    margin-top: 2rem;

    @include breakpoint(tablet) {
        margin-right: 0;
    }

    @include breakpoint(mobile) {
        margin-right: 1.6rem;
    }
    &:last-child {
        margin-right: 0;
    }
}

.how-to-choose__card-write-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    font-family: var(--nunito);
    color: var(--text-color);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    padding: 2.3rem 4rem;
    flex-shrink: 0;
    white-space: nowrap;
    user-select: none;
    border-radius: 2rem;
    border: 1px solid var(--primary-color);

    .icon-question {
        display: none;
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        fill: var(--primary-color);
    }

    @include breakpoint(small-tablet) {
        width: 100%;
    }

    @include breakpoint(mobile) {
        width: 4.8rem;
        height: 4.8rem;
        padding: 0;
        border-radius: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--primary-color);
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2.5rem;

        .icon-question {
            display: block;
        }
    }
    &:link,
    &:visited {
        color: var(--text-color);
    }

    .no-touch &:hover,
    .touch &:active {
        background-color: var(--primary-color);
        color: white;
    }
}

.how-to-choose__card-write-btn-text {
    @include breakpoint(mobile) {
        display: none;
    }
}
