.intro {
    position: relative;
    z-index: 1;
    color: white;
    background-color: var(--primary-color);
}

.intro__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, #654134 3.5%, rgba(164, 121, 89, 0) 51.9%);
        opacity: 0.75;
    }
}

.intro__bg-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;

    &--blurred {
        opacity: 0;
        visibility: hidden;
    }
}

.intro__text-content {
    margin-top: auto;

    @include breakpoint(mobile) {
        margin: 0;
        margin-bottom: 6rem;
    }
}

.intro__content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 10rem 0;

    @include breakpoint(tablet) {
        min-height: 100rem;
    }

    @include breakpoint(mobile) {
        min-height: 100vh;
        padding-top: 13.5rem;
    }
}

.intro__mobile-logo {
    display: none;
    @include breakpoint(tablet) {
        display: block;
        height: auto;
        width: 24rem;
        margin-bottom: 6rem;
    }

    @include breakpoint(mobile) {
        width: 12rem;
        margin-bottom: 4rem;
    }
}

.intro__heading {
    font-weight: bold;
    font-size: 5.2rem;
    line-height: 6rem;
    margin-bottom: 5.5rem;
    font-family: var(--nunito);
    max-width: 70rem;

    @include breakpoint(mobile) {
        font-size: 2.6rem;
        line-height: 3.2rem;
        margin-bottom: 2.4rem;
        max-width: none;
    }
}

.intro__buttons {
    display: flex;
    align-items: center;
}

.intro__calculate-btn {
    margin-right: 2.4rem;

    @include breakpoint(mobile) {
        margin-right: 1.6rem;
    }
}

.intro__get-consultation {
    display: inline-flex;
    align-items: center;
    justify-self: center;
    border-radius: 2rem;
    background-color: #fff;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    font-family: var(--nunito);
    padding: 2rem 2.2rem;
    color: var(--text-color);
    transition: color 0.2s, background-color 0.2s;

    @include breakpoint(mobile) {
        width: 4.8rem;
        height: 4.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        line-height: 1.2;
        padding: 0;
        border-radius: 1.5rem;
       
    }
    &:link,
    &:visited {
        color: var(--text-color);
    }

    svg {
        width: 3.2rem;
        height: 3.2rem;
        flex-shrink: 0;
        fill: var(--primary-color);
        margin-right: 1.3rem;
        transition: fill 0.2s;

        @include breakpoint(mobile) {
            width: 2rem;
            height: 2rem;
            margin: 0;
        }
    }

    .no-touch &:hover,
    .touch &:active {
        background-color: var(--primary-color);
        color: white;

        svg {
            fill: white;
        }
    }
}

.intro__get-consultation-text {
    @include breakpoint(mobile) {
        display: none;
    }
}

.intro__features-list {
    margin-top: auto;
    display: flex;
    align-items: flex-start;

    @include breakpoint(mobile) {
        margin: 0;
        align-items: initial;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 4rem;
        grid-row-gap: 3.2rem;
    }
}

.intro__features-list-item {
    flex-basis: 18rem;
    margin-right: 6.5rem;

    @include breakpoint(mobile) {
        flex-basis: auto;
        margin: 0;
    }
    &:last-child {
        margin-right: 0;
    }
}

.intro__features-icon {
    height: 5.4rem;
    width: auto;
    max-width: 100%;
    object-fit: contain;
    object-position: left center;
    flex-shrink: 0;
    margin-bottom: 2rem;

    @include breakpoint(mobile) {
        height: 3.6rem;
        margin-bottom: 1.2rem;
    }
}

.intro__features-card-text {
    font-size: 2rem;
    line-height: 2.4rem;
    font-family: var(--nunito);

    font-weight: bold;

    @include breakpoint(mobile) {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }
}
