.benefits {
    padding-top: 12rem;
    padding-bottom: 10rem;

    @include breakpoint(mobile) {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
    }
}

.benefits__intro {
    margin-bottom: 8rem;
    position: relative;
    z-index: 1;

    @include breakpoint(mobile) {
        margin-bottom: 2rem;
    }
    &::before {
        content: '';
        position: absolute;
        right: 0;
        z-index: -1;
        pointer-events: none;
        width: 45.5rem;
        height: 53.2rem;
        background-image: url('../img/benefits-bg-icon.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include breakpoint(mobile) {
            display: none;
        }
    }
}

.benefits__intro-content {
    max-width: 90rem;

    @include breakpoint(mobile) {
        max-width: none;
    }
}

.benefits__heading {
    margin-bottom: 4rem;

    @include breakpoint(mobile) {
        margin-bottom: 2rem;
    }
}

.benefits__text {
    font-size: 2.4rem;
    line-height: 3.6rem;

    @include breakpoint(mobile) {
        font-size: 1.8rem;
        line-height: 2.6rem;
    }
}

.benefits__list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 8rem;
    grid-row-gap: 6rem;

    @include breakpoint(tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoint(mobile) {
        grid-column-gap: 2rem;
        grid-row-gap: 1.5rem;
    }
}

.benefits__list-item {
    display: flex;
    flex-direction: column;
}

.benefits__card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    user-select: none;
    position: relative;
    z-index: 1;

    height: 50rem;
    color: #231f20;
    transition: z-index 0.6s step-end;

    @include breakpointMF(laptop) {
        .benefits__list-item:nth-child(3n + 1) & {
            z-index: 2;

            &.active {
                z-index: 100;
            }
        }
    }

    @include breakpoint(mobile) {
        height: 22.5rem;
    }

    &.active {
        z-index: 50;
        transition: z-index 0.6s step-start;
    }
}

.benefits__card-content {
    padding: 4rem;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: width 0.6s;
    &::before {
        content: '';
        width: 100%;
        height: 1px;
        flex-grow: 1;
        transition: flex-grow 0.6s;
    }

    @include breakpointMF(laptop) {
        .benefits__list-item:nth-child(3n) & {
            left: auto;
            right: 0;
        }
    }

    @include breakpoint(tablet) {
        .benefits__list-item:nth-child(2n) & {
            left: auto;
            right: 0;
        }
    }

    @include breakpoint(mobile) {
        padding: 1.2rem;
    }

    .benefits__card.active & {
        width: calc(200% + 8rem + 1px);

        @include breakpoint(mobile) {
            width: calc(200% + 2rem + 1px);
        }

        &::before {
            flex-grow: 0;
        }
    }
}

.benefits__card-btn {
    width: 6.6rem;
    height: 6.6rem;
    position: absolute;
    right: 4rem;
    top: 4rem;
    background-color: var(--primary-color);
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s, background-color 0.2s;
    cursor: pointer;

    @include breakpoint(mobile) {
        width: 3.2rem;
        height: 3.2rem;
        top: 1.2rem;
        right: 1.2rem;
    }
    svg {
        width: 2.2rem;
        height: 2.2rem;
        fill: white;
        flex-shrink: 0;

        @include breakpoint(mobile) {
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    .no-touch &:hover {
        background-color: var(--primary-color-hover);
    }

    .benefits__card.active & {
        transform: rotate(45deg);
    }
}

.benefits__card-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        transition: opacity 0.6s;
        opacity: 0;
        z-index: 10;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(203.37deg, rgba(255, 255, 255, 0) 45.82%, rgba(255, 255, 255, 0.82) 87.24%);
        transition: opacity 0.6s;
        z-index: 5;
    }

    .benefits__card.active & {
        &::before {
            opacity: 0;
        }
        &::after {
            opacity: 1;
        }
    }
}

.benefits__card-bg-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: left center;

    .benefits__list-item:nth-child(3n + 3) & {
        object-position: right center;
    }
}

.benefits__card-text {
    font-size: 2.4rem;
    line-height: 3.6rem;

    transform: translateY(100%);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s linear 0.3s, transform 0.3s;
    padding: 4rem;
    padding-bottom: 3.5rem;

    @include breakpoint(mobile) {
        padding: 1.2rem;
        font-size: 1.2rem;
        line-height: 16px;
    }
    .benefits__card.active & {
        opacity: 1;
        visibility: inherit;
        transition: opacity 0.3s ease 0.3s, visibility 0.3s ease 0.3s, transform 0.3s ease 0.3s;
        transform: translateY(0%);
    }
}

.benefits__card-title {
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 120%;
    font-family: var(--nunito);
  
    padding-top: 1.4rem;

   

    
    .benefits__card:not(.active) & {
        padding-right: 7rem;
        animation-name: fade-title-in-out-close;
        animation-duration: 0.5s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
    }
    .benefits__card.active & {
        padding-right: 7rem;
        animation-name: fade-title-in-out;
        animation-duration: 0.5s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
    }

    @include breakpoint(mobile) {
        padding-right: 0;
        padding-top: 1rem;
        font-size: 1.2rem;
        line-height: 120%;

        .benefits__card.active & {
            padding-right: 4rem;
        }
    }
}

@keyframes fade-title-in-out {
    0% {
        opacity: 1;
    }

    20% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fade-title-in-out-close {
    0% {
        opacity: 1;
    }

    20% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
