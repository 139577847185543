.not-found {
    padding: 15rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    @include breakpoint(mobile) {
        padding: 7rem 0;
    }
}

.not-found__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 6.4rem;
    align-items: center;

    @include breakpoint(mobile) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 1rem;
    }
}
.thanks__content {
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(mobile) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 1rem;
    }
}

.not-found__code {
    font-size: 39.6rem;
    line-height: 54rem;
    font-family: var(--nunito);
    letter-spacing: -0.02em;
    text-align: right;
    justify-self: flex-end;

    @include breakpoint(mobile) {
        font-size: 18rem; 
        line-height: 1;
        justify-self: initial;
        text-align: left;
    }
}

.not-found__heading {
    font-weight: 500;
    font-size: 4.4rem;
    line-height: 5rem;
    margin-bottom: 2rem;

    @include breakpoint(mobile) {
        font-size: 2.8rem;
        line-height: 1.2;
        margin-bottom: 1.5rem;
    }
}
.thanks__heading {
    font-weight: 600;
    font-size: 6.4rem;
    line-height: 5rem;
    margin-bottom: 2rem;

    @include breakpoint(mobile) {
        font-size: 3.8rem;
        line-height: 1.2;
        margin-bottom: 1.5rem;
    }
}

.not-found__text {
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-bottom: 3.5rem;

    @include breakpoint(mobile) {
        font-size: 1.8rem;
        line-height: 1.3;
        margin-bottom: 2rem;
    }
}
.thanks__text {
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-bottom: 3.5rem;

    @include breakpoint(mobile) {
        font-size: 1.8rem;
        line-height: 1.3;
        margin-bottom: 2rem;
    }
    img {
        margin: 0px 0 15px 0;
    }
}
