.page-footer {
    background-color: #fff;
    padding-top: 7.5rem;
    padding-bottom: 10rem;
    color: var(--text-color);

    @include breakpoint(mobile) {
        padding-top: 5.5rem;
        padding-bottom: 2.5rem;

        .container {
            display: flex;
            flex-direction: column;
        }
    }
}

.page-footer__top-row {
    margin-bottom: 6rem;
    display: flex;
    align-items: center;

    @include breakpoint(mobile) {
        flex-direction: column;
        align-items: initial;
        margin-bottom: 0;
        display: contents;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.page-footer__logo {
    flex-shrink: 0;
    margin-right: 15rem;
    position: relative;
    display: block;

    @include breakpoint(mobile) {
        flex-shrink: 1;
        margin: 0;
        margin-bottom: 3.5rem;
    }
}

.page-footer__logo-image {
    display: block;
    height: auto;
    width: 23rem;
    @include breakpoint(mobile) {
        width: 15.4rem;
    }
}

.page-footer__content {
    width: 50%;
    flex-grow: 1;
    display: flex;
    align-items: center;

    @include breakpoint(mobile) {
        width: 100%;
        flex-grow: 0;
        align-items: initial;
        flex-direction: column;
        display: contents;
    }
}

.page-footer__copyright {
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: rgba(black, 0.2);
    width: 63rem;
    margin-right: 4rem;

    @include breakpoint(mobile) {
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin: 0;
        width: auto;
        margin-bottom: 3rem;
        order: 9;
        margin-top: 1.8rem;
        
    }
}

.page-footer__contacts-block {
    flex-shrink: 0;
    margin-left: 4rem;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas: 'footer-phone-link footer-whatsapp-link' 'footer-phone-link-text footer-whatsapp-link';
    text-align: right;
    grid-column-gap: 2.2rem;
    grid-row-gap: 0.5rem;
    flex-shrink: 0;
    margin-left: auto;

    @include breakpoint(mobile) {
        flex-shrink: 1;
        margin: 0;
        display: flex;
        flex-direction: column;
        grid-gap: 0;
        text-align: left;
        margin-bottom: 4rem;
    }
}

.page-footer__phone-link {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    font-family: var(--nunito);
    grid-area: footer-phone-link;
    justify-self: flex-end;

    @include breakpoint(mobile) {
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.4rem;
        justify-self: initial;
        margin-bottom: 0.3rem;
    }
}

.page-footer__phone-link-text {
    justify-self: flex-end;
    grid-area: footer-phone-link-text;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;
    @include breakpoint(mobile) {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }
}

.page-footer__whats-app-link {
    width: 4.7rem;
    height: 4.7rem;
    flex-shrink: 0;
    grid-area: footer-whatsapp-link;

    @include breakpoint(mobile) {
        display: none;
    }
    svg {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.page-footer__bottom-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include breakpoint(mobile) {
        flex-direction: column;
        align-items: initial;
        justify-content: initial;
        display: contents;
    }
}

.page-footer__bottom-link {
    font-size: 1.4rem;
    line-height: 2.3rem;
    margin-right: 5rem;
    @include breakpoint(mobile) {

        margin-bottom: .7rem;
    }

    .no-touch &:hover {
        text-decoration: underline;
    }
}

.page-footer__made-by {
    display: inline-flex;
    align-items: center;
    font-size: 1.2rem;
    line-height: 114%;
    color: #d1d1d3;
    flex-shrink: 0;

    @include breakpoint(mobile) {
        order: 10;
        justify-content: flex-end;
    }

    &:link,
    &:visited {
        color: #d1d1d3;
    }
}

.page-footer__made-by-image {
    width: 8.5rem;
    height: auto;
    margin-left: 1.2rem;
    flex-shrink: 0;
    margin-top: 0.8rem;
}
