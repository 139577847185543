.table-comparison {
    padding: 10rem 0;
    background-color: white;
    position: relative;
    z-index: 1;

    @include breakpoint(mobile) {
        padding-top: 8rem;
        padding-bottom: 6rem;
    }
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
        width: 100%;
        font-weight: 400;
        text-align: left;
    }

    th {
        font-family: var(--nunito);
        font-weight: bold;
        font-size: 2.8rem;
        line-height: 120%;

        @include breakpoint(mobile) {
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 110%;
        }
    }

    tr {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        grid-column-gap: 8rem;
        position: relative;

        @include breakpoint(mobile) {
            grid-column-gap: 2rem;
        }
    }

    tbody tr {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: -1.8rem;
            right: -1.8rem;
            top: 0rem;
            bottom: 0rem;
            background: rgba(198, 198, 204, 0.2);
            border-radius: 1rem;
            transition: opacity 0.2s;
            z-index: -1;
            opacity: 0;

            @include breakpoint(mobile) {
                display: none;
            }
        }
        .no-touch &:hover {
            font-weight: 500;

            td {
                font-weight: 500;
            }
            &::before {
                opacity: 1;
            }
        }
    }

    td {
        font-size: 2rem;
        line-height: 1.2;
        padding: 1.4rem 0;

        @include breakpoint(mobile) {
            font-size: 1.2rem;
            line-height: 1.6rem;
            padding: .3rem 0;
        }
    }

    td:first-child {
        display: flex;
        align-items: center;

        @include breakpoint(mobile) {
            align-items: flex-start;
        }
        &::before {
            content: '';
            width: 2.5rem;
            height: 2.2rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../img/comparison-table/checkmark.svg');
            margin-right: 2.6rem;
            flex-shrink: 0;

            @include breakpoint(mobile) {
                width: 1.3rem;
                height: 1rem;
                margin-right: 0.6rem;
                align-self: flex-start;
                margin-top: 0.2rem;
            }
        }
    }

    td:last-child {
        display: flex;
        align-items: center;
        @include breakpoint(mobile) {
            align-items: flex-start;
        }
        &::before {
            content: '';
            width: 1.8rem;
            height: 1.8rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../img/comparison-table/cross.svg');
            margin-right: 2.3rem;
            flex-shrink: 0;

            @include breakpoint(mobile) {
                width: 1.1rem;
                height: 1.1rem;
                margin-right: 0.6rem;
                align-self: flex-start;
                margin-top: 0.25rem;
            }
        }
    }
}

.table-comparison__heading {
    display: flex;
    align-items: flex-start;
    margin-bottom: 3.5rem;

    @include breakpoint(mobile) {
        flex-direction: column;
        align-items: initial;
        margin-bottom: 2.2rem;
        position: relative;
    }
}

.table-comparison__heading-logo {
    width: 10rem;
    flex-shrink: 0;
    height: auto;
    margin-left: 2rem;
    margin-top: 0.8rem;

    @include breakpoint(mobile) {
        margin: 0;
        width: 7rem;
        position: absolute;
        bottom: 100%;
        left: 0;
        margin-bottom: 1.4rem;
        
    }
}
