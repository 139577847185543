.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    font-family: var(--nunito);
    background-color: var(--primary-color);
    color: white;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    padding: 2.3rem 4rem;
    flex-shrink: 0;
    white-space: nowrap;
    user-select: none;
    border-radius: 2rem;
    border: 1px solid var(--primary-color);

    &:not(.btn--hollow):not(.btn--white) {
        @include cta-hover();
    }

 

    @include breakpoint(mobile) {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.2;
        padding: 1.6rem 2.8rem;
        border-radius: 1.4rem;
    }

    &:link,
    &:visited {
        color: white;
    }

    &--hollow {
        background-color: #fff;
        color: var(--text-color);

        &:link,
        &:visited {
            color: var(--text-color);
        }
    }

    &--white {
        background-color: white;
        border-color: white;
        color: var(--text-color);

        &:link,
        &:visited {
            color: var(--text-color);
        }
    }

    .no-touch &:not(.btn--hollow):not(.btn--white):hover,
    .touch &:not(.btn--hollow):not(.btn--white):active {
        background-color: var(--primary-color-hover);
        border-color: var(--primary-color-hover);
        color: white;
    }

    .no-touch &.btn--hollow:hover,
    .touch &.btn--hollow:active {
        background-color: var(--primary-color);
        color: white;
    }
}
