.features {
    padding: 8rem 0;

    @include breakpoint(mobile) {
        padding: 4.8rem 0;
    }
}

.features__row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 8rem;
    display: grid;

    @include breakpoint(mobile) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 3.2rem;
    }
}

.features__col {
    display: flex;
    flex-direction: column;
}

.features__heading {
    margin-bottom: 3.5rem;
    margin-top: -0.3rem;

    @include breakpoint(mobile) {
        margin-bottom: 2.4rem;
    }
}

.features__text {
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-bottom: 6rem;

    @include breakpoint(mobile) {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 3rem;
    }
}

.features__images-gallery {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: minmax(4rem, 1fr) 7rem minmax(10rem, 1fr);
    grid-auto-flow: dense;
    grid-gap: 2.5rem;
    min-height: 50rem;

    @include breakpoint(mobile) {
        min-height: 29rem;
        grid-gap: 1.2rem;
        grid-template-rows: minmax(4rem, 1fr) 2.8rem minmax(4rem, 1fr);
    }
}

.features__images-gallery-card {
    position: relative;
    border-radius: 1.8rem;
    overflow: hidden;

    @include breakpoint(mobile) {
        border-radius: 0.8rem;
    }

    &:nth-child(1) {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
    }
    &:nth-child(2) {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
    }
    &:nth-child(3) {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
    }
    &:nth-child(4) {
        grid-row: 2 / 4;
        grid-column: 2 / 3;
    }
}

.features__images-gallery-card-photo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include objectFitCover();
}

.features__list-item {
    margin-bottom: 4.6rem;

    @include breakpoint(mobile) {
        margin-bottom: 3.2rem;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.features__card-title {
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 120%;
    font-family: var(--nunito);
    margin-bottom: 2rem;

    @include breakpoint(mobile) {
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 120%;
        margin-bottom: 1.6rem;
    }
}

.features__card-text {
    font-size: 2.2rem;
    line-height: 3.1rem;
    color: #7a7a7a;

    @include breakpoint(mobile) {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
}
